<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12 order-lg-12 order-xl-12">
        <KTPortlet
          v-bind="{
            title: t('GLOBAL.STATISTICS'),
            class: 'kt-portlet--fit kt-portlet--skin-solid',
            headClass: 'kt-portlet__head--noborder kt-portlet__space-x',
            headSize: 'lg',
            fluidHeight: false,
            headOverlay: true,
            headNoBorder: true,
          }"
        >
          <template v-slot:body>
            <div class="kt-widget17">
              <div
                class="kt-widget17__visual kt-widget17__visual--chart kt-portlet-fit--top kt-portlet-fit--sides"
                style="background-color: #fd397a"
              >
                <div
                  class="kt-widget17__chart"
                  style="height:110px;margin-top:15px"
                ></div>
              </div>
              <div class="kt-widget17__stats">
                <div class="kt-widget17__items">
                  <div class="kt-widget17__item">
                    <span class="kt-widget17__icon">
                      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--brand'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M18 14a3 3 0 110-6 3 3 0 010 6zm-9-3a4 4 0 110-8 4 4 0 010 8z" fill="#000" fill-rule="nonzero" opacity=".3"/><path d="M17.601 15c3.407.038 6.188 1.76 6.397 5.4.009.147 0 .6-.542.6H19.6c0-2.25-.744-4.328-1.999-6zm-17.6 5.2C.388 15.426 4.26 13 8.983 13c4.788 0 8.722 2.293 9.015 7.2.012.195 0 .8-.751.8H.727c-.25 0-.747-.54-.726-.8z" fill="#000" fill-rule="nonzero"/></g></svg>
                    </span>
                    <span class="kt-widget17__subtitle">{{
                      t("GLOBAL.COUNTS")
                    }}</span>
                    <span class="kt-widget17__desc"
                      >{{ stats.farmers.count }}
                      {{ t("GLOBAL.FARMER.D") }}</span
                    >
                    <span class="kt-widget17__desc"
                      >{{ stats.consultants.count }}
                      {{ t("GLOBAL.CONSULTANTS.D") }}</span
                    >
                    <span class="kt-widget17__desc"
                      >{{ stats.buyers.count }} {{ t("GLOBAL.BUYERS.D") }}</span
                    >
                    <span class="kt-widget17__desc"
                      >{{ stats.logisticCompanies.count }}
                      {{ t("GLOBAL.LOGISTICS") }}</span
                    >
                  </div>
                  <div class="kt-widget17__item">
                    <span class="kt-widget17__icon">
                      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--brand'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M12.934 16.072l6.426-5.116.158-.125a1.5 1.5 0 00.003-2.35l-6.587-5.238a1.5 1.5 0 00-1.868 0L4.475 8.485a1.5 1.5 0 00-.001 2.347l.156.124 6.436 5.117a1.5 1.5 0 001.868 0z" fill="#000" fill-rule="nonzero"/><path d="M11.056 18.67l-5.72-4.547a.903.903 0 00-1.276.154.91.91 0 00.166 1.279l6.861 5.247a1.5 1.5 0 001.823 0l6.858-5.243a.915.915 0 00.167-1.288.91.91 0 00-1.286-.155l-5.725 4.554a1.5 1.5 0 01-1.868 0z" fill="#000" opacity=".3"/></g></svg>
                    </span>
                    <span class="kt-widget17__subtitle">{{
                      t("GLOBAL.LAND.P")
                    }}</span>
                    <span class="kt-widget17__desc"
                      >{{ stats.farmers.lands.count }}
                      {{ t("GLOBAL.PIECE") }}</span
                    >
                    <span class="kt-widget17__desc"
                      >{{ stats.farmers.lands.area }} m2</span
                    >
                  </div>
                  <div class="kt-widget17__item">
                    <span class="kt-widget17__icon">
                      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--success'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M3 4h17a1 1 0 011 1v2a1 1 0 01-1 1H3a1 1 0 01-1-1V5a1 1 0 011-1zm0 6h10a1 1 0 011 1v8a1 1 0 01-1 1H3a1 1 0 01-1-1v-8a1 1 0 011-1z" fill="#000"/><rect fill="#000" opacity=".3" x="16" y="10" width="5" height="10" rx="1"/></g></svg></span>
                    <span class="kt-widget17__subtitle">{{
                      t("GLOBAL.CROPLAND.P")
                    }}</span>
                    <span class="kt-widget17__desc"
                      >{{ stats.farmers.cropLands.count }}
                      {{ t("GLOBAL.PIECE") }}</span
                    >
                    <span class="kt-widget17__desc"
                      >{{ stats.farmers.cropLands.area }} m2</span
                    >
                  </div>
                  <div class="kt-widget17__item">
                    <span class="kt-widget17__icon">
                      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--success'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M12.886 11.478c-1.601-5.836-.611-9.529 2.97-11.08 2.204 3.101 1.215 6.795-2.97 11.08z" fill="#000" opacity=".3"/><path d="M23.06 7.669c-5.836-1.601-9.53-.612-11.08 2.969 3.1 2.205 6.794 1.215 11.08-2.969z" fill="#000" opacity=".3"/><path d="M20.005 3.495c-5.854 1.53-8.558 4.234-8.11 8.11 3.787.36 6.49-2.344 8.11-8.11z" fill="#000" opacity=".3"/><path d="M2.578 17.513l.29.29a1 1 0 101.414-1.414l-.69-.691 1.012-1.816 1.093 1.093A1 1 0 007.11 13.56l-1.414-1.415a1.011 1.011 0 00-.083-.074l1.016-1.82 1.895 1.894a1 1 0 101.414-1.414L7.818 8.611a1.002 1.002 0 00-.19-.15l.245-.437a2 2 0 013.16-.44l4.883 4.883a2 2 0 01-.44 3.16l-12.007 6.7a1.687 1.687 0 01-2.295-2.296l1.404-2.518z" fill="#000"/></g></svg></span>
                    <span class="kt-widget17__subtitle">{{
                      t("GLOBAL.HARVEST.P")
                    }}</span>
                    <span class="kt-widget17__desc"
                      >{{ stats.farmers.harvests.count }}
                      {{ t("GLOBAL.PIECE") }}</span
                    >
                    <span class="kt-widget17__desc"
                      >{{ stats.farmers.harvests.amount }} Kg</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </template>
        </KTPortlet>
      </div>
      <div class="col-lg-12 col-xl-12 order-lg-12 order-xl-12">
        <KTPortlet>
          <template v-slot:body>
            <HarvestChart />
          </template>
        </KTPortlet>
      </div>
      <div class="col-lg-12 col-xl-12 order-lg-12 order-xl-12">
        <KTPortlet
          v-bind="{
            title: t('GLOBAL.MAPTITLE'),
          }"
        >
          <template v-slot:body>
            <iframe
              src="https://www.uzmanciftci.com/Home/HomeMapView"
              width="100%"
              height="430px"
              noborder="0"
              scrolling="false"
              frameBorder="0"
              seamless
            ></iframe>
          </template>
        </KTPortlet>
      </div>
      <div class="col-lg-12 col-xl-12 order-lg-12 order-xl-12">
        <!--Begin::Recent Activities-->
        <KTPortlet v-bind:title="t('GLOBAL.RECENTACTIVITIES')">
          <template v-slot:body>
            <!--Begin::Timeline 3 -->
            <Timeline2 v-bind:datasrc="timelines"></Timeline2>
            <!--End::Timeline 3 -->
          </template>
        </KTPortlet>
        <!--End::Recent Activities-->
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.store";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import Timeline2 from "@/views/partials/widgets/Timeline2.vue";

import i18n from "../../plugins/vue-i18n";
import HarvestChart from "../../components/dashboard/HarvestChart.vue";

export default {
  name: "dashboardOld",
  components: {
    KTPortlet,
    Timeline2,
    HarvestChart,
  },
  data() {
    return {
      timelines: [],
      stats: {
        farmers: {
          count: 0,
          lands: {
            count: 0,
            area: 0,
          },
          cropLands: {
            count: 0,
            area: 0,
          },
          harvests: {
            count: 0,
            amount: 0,
          },
        },
        consultants: {
          count: 0,
        },
        buyers: {
          count: 0,
        },
        logisticCompanies: {
          count: 0,
        },
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.t("GLOBAL.DASHBOARD") },
    ]);
    this.apiSendGraph(
      "histories",
      ` id,objectId,dateTime,module{name},action{name,code} `,
      "id DESC",
      {},
      1,
      10
    ).then((data) => {
      this.timelines = data["data"];
      this.timelines.forEach((o) => {
        let d = new Date(o.dateTime);
        o.time =
          d
            .getUTCHours()
            .toString()
            .padStart(2, "0") +
          ":" +
          d
            .getMinutes()
            .toString()
            .padStart(2, "0");
        o.icon = "fa fa-genderless ";
        o.icon += o.action.code == "post" ? "kt-font-success" : "";
        o.icon += o.action.code == "put" ? "kt-font-info" : "";
        o.icon += o.action.code == "delete" ? "kt-font-danger" : "";
        o.text = o.module.name + " " + o.objectId + " ";
        o.text += o.action.code == "post" ? i18n.t("MESSAGES.ADDED") : "";
        o.text += o.action.code == "put" ? i18n.t("MESSAGES.UPDATED") : "";
        o.text += o.action.code == "delete" ? i18n.t("MESSAGES.DELETED") : "";
      });
    });
    this.apiSend("get", "stats").then((data) => {
      this.stats = data;
    });
  },
  methods: {},
};
</script>
