<template>
  <div class="row">
    <div class="col-md-6 col-sm-12">
      <KTPortlet v-bind="{ title: t('GLOBAL.TOTALHARVESTS') }">
        <template v-slot:body>
          <canvas id="barChart" ref="barChart"></canvas>
        </template>
      </KTPortlet>
    </div>
    <div class="col-md-6 col-sm-12">
      <KTPortlet v-bind="{ title: t('GLOBAL.HARVESTPRODUCT') }">
        <template v-slot:body>
          <canvas id="pieChart" ref="pieChart"></canvas>
        </template>
      </KTPortlet>
    </div>
    <div class="col-md-6 col-sm-12">
      <KTPortlet v-bind="{ title: t('GLOBAL.TOPPRODUCTSYEAR') }">
        <template v-slot:body>
          <canvas id="lineChart" ref="lineChart"></canvas>
        </template>
      </KTPortlet>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";
import i18nService from "@/services/i18n.service.js";

export default {
  data() {
    return {
      harvestsData: null,
    };
  },
  created() {
    this.fetchHarvests();
  },
  mounted() {
    if (this.harvestsData) {
      this.renderCharts();
    }
  },
  methods: {
    async fetchHarvests() {
      const query = `
        {
          harvests {
            data {
              amount
              createdAt
              cropLandId
              endDate
              farmerId
              id
              cropLand {
                id
                name
                land {
                  id
                  name
                  city {
                    id
                    name
                  }
                }
                productKind {
                  id
                  name,
                  name_En,
                  product {
                    id
                    name,
                    name_En
                  }
                }
              }
              name
              productId
              productKindId
              startDate
              unitPrice
            }
          }
        }
      `;

      try {
        const response = await this.apiSend("POST", "/graphql", { query });
        this.harvestsData = response?.harvests?.data;
        this.renderCharts();
      } catch (error) {
        // console.error("Error fetching harvests:", error);
      }
    },
    transformTopProductsByYear(harvests) {
      const yearProductTotals = {};

      harvests.forEach((harvest) => {
        const year = new Date(harvest.createdAt).getFullYear();
        const productName =
          i18nService.getActiveLanguage() == "tr"
            ? harvest.cropLand.productKind.product.name
            : harvest.cropLand.productKind.product.name_En;
        const amount = harvest.amount;

        if (!yearProductTotals[year]) {
          yearProductTotals[year] = {};
        }
        yearProductTotals[year][productName] =
          (yearProductTotals[year][productName] || 0) + amount;
      });

      const topProductsByYear = {};
      for (const year in yearProductTotals) {
        topProductsByYear[year] = Object.entries(yearProductTotals[year])
          .sort((a, b) => b[1] - a[1])
          .slice(0, 10)
          .map(([product, total]) => ({ product, total }));
      }

      return topProductsByYear;
    },
    transformProductAmountData(harvests) {
      const productTotals = {};

      harvests.forEach((harvest) => {
        const productName =
          i18nService.getActiveLanguage() == "tr"
            ? harvest.cropLand.productKind.product.name
            : harvest.cropLand.productKind.product.name_En;
        const amount = harvest.amount;

        productTotals[productName] = (productTotals[productName] || 0) + amount;
      });

      return Object.entries(productTotals).map(([product, total]) => ({
        product,
        total,
      }));
    },
    generateRandomColor() {
      return `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(
        Math.random() * 256
      )}, ${Math.floor(Math.random() * 256)}, 0.6)`;
    },
    generateDistinctColor(index) {
      const hue = (index * 40) % 360;
      return `hsl(${hue}, 50%, 70%)`;
    },
    renderCharts() {
      const productAmountData = this.harvestsData
        ? this.transformProductAmountData(this.harvestsData)
        : [];
      const topProductsByYear = this.transformTopProductsByYear(
        this.harvestsData
      );
      const years = Object.keys(topProductsByYear);

      const datasets = years.map((year) => ({
        label: year,
        data: topProductsByYear[year].map((item) => item.total),
        backgroundColor: this.generateDistinctColor(years.indexOf(year)),
      }));

      const lineCtx = this.$refs.lineChart.getContext("2d");
      new Chart(lineCtx, {
        type: "line",
        data: {
          labels: topProductsByYear[years[0]].map((item) => item.product),
          datasets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                stepSize: 5000000,
              },
            },
          },
        },
      });

      const barCtx = this.$refs.barChart.getContext("2d");
      new Chart(barCtx, {
        type: "bar",
        data: {
          labels: productAmountData.map((data) => data.product),
          datasets: [
            {
              label:
                i18nService.getActiveLanguage() == "tr"
                  ? "Toplam Hasat Miktarı"
                  : "Total Harvests",
              data: productAmountData.map((data) => data.total),
              backgroundColor: productAmountData.map(() =>
                this.generateRandomColor()
              ),
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
          maintainAspectRatio: false,
        },
      });

      const pieCtx = this.$refs.pieChart.getContext("2d");
      new Chart(pieCtx, {
        type: "pie",
        data: {
          labels: productAmountData.map((data) => data.product),
          datasets: [
            {
              label: "Ürün Bazında Miktar",
              data: productAmountData.map((data) => data.total),
              backgroundColor: productAmountData.map(() =>
                this.generateRandomColor()
              ),
              borderColor: "#fff",
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      });
    },
  },
};
</script>

<style scoped>
canvas {
  max-width: 800px;
  max-height: 400px;
  margin: 20px auto;
}
@media screen and (max-width: 575px) {
  #pieChart {
    height: 100%;
    max-height: 800px;
  }
}
</style>
